import SlimSelect from 'slim-select'

export default () => {
  const selects = document.querySelectorAll('.js-slim-select')

  selects.forEach((item) => {
    new SlimSelect({
      select: item,
      showSearch: false,
      onChange: () => {
        item.classList.add('is-selected')
        const field = item.closest('.field')
        if (field !== null) {
          field.classList.remove('field-error')
        }
      }
    })
    item.removeAttribute('style')
  })
}
